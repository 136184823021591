<template>
  <div>
    <v-container>
      <v-row class="mx-3">
        <div class="headline">
          {{ COMMON.USERS_TITLE }}
        </div>
      </v-row>

      <v-row>
        <v-col>
          <v-data-table
            :headers="headers"
            :items="!isBusy ? users : []"
            :items-per-page="10"
            hide-default-footer
            class="elevation-1"
            :loading="isBusy"
            loading-text="Loading... Please wait"
            :page.sync="page"
            @page-count="pageCount = $event"
          />

          <v-pagination
            v-model="page"
            :length="pageCount"
          />
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import commons from '@/commons/common';
import { mapState } from 'vuex';
export default {

  data() { return {
    COMMON: commons,
    isBusy: true,

    headers: [
      {
        text: 'Name',
        align: 'start',
        value: 'name',
        sortable: true,
      },
      { text: 'Email', value: 'email', sortable: true },
      { text: 'Phone', value: 'phoneNumber' },
      { text: 'Address', value: 'address', sortable: true },
      { text: 'Language', value: 'language.name', sortable: true },
      { text: 'DOB', value: 'dob', sortable: false },
    ],

    page: 1,
    pageCount: 0,
  };},

  computed: {
    ...mapState({ users: state => state.users._users }),
  },

  mounted() {
    this.loadUsers();
  },

  methods: {
    loadUsers() {
      this.isBusy = true;
      this.$store.dispatch('users/retrieveUsers').then((res) => {
        if (res.status == 200) this.isBusy = false;
      });
    },
  },
};
</script>

<style>

</style>
